import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { rememberLogin } from "../../../../config/store/localRedux/actions";
import { Form, Input, Button, Checkbox } from "@pmviulive/msd-reactjs";
import ForgotPassword from "../forgotPassword/forgotPassword";
import Register from "../signup/signup";
import i18n from "../../../../config/i18n";

class LoginForm extends Component {
  state = {
    username: null,
    password: null,
  };
  componentDidMount() {
    if (this.props.location.pathname === "/register") {
      this.props.popupHandler(<Register />);
    }
  }
  onChangeHandler = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };
  rememberMe = (e) => {
    this.props.rememberLogin(e.target.checked);
  };
  render() {
    return (
      <div className="MSD-Login">
        <Form onSubmit={(e) => this.onSubmit(e)}>
          <Form.Item>
            <Input
              placeholder={`${i18n.t("text.email")}`}
              type="text"
              onChange={(e) => this.onChangeHandler("username", e)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder={`${i18n.t("text.password")}`}
              type="password"
              autoComplete="off"
              onChange={(e) => this.onChangeHandler("password", e)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={this.rememberMe}
              checked={this.props.checked}
              className="rememberLogin"
            >
              {i18n.t("text.rememberMe")}
            </Checkbox>
          </Form.Item>
          <div className="loginFormBtn">
            <Form.Item>
              <Button
                htmlType="submit"
                className="transparentBtn"
                onClick={(e) => this.onSubmit(e)}
                size="small"
              >
                <i className="icon-login"></i>
                {i18n.t("text.login")}
              </Button>
            </Form.Item>
            <div
              className="forgotPasswordLink"
              onClick={() => this.props.popupHandler(<ForgotPassword />)}
            >
              <span>
                <i className="icon-forgot-password"></i>
                {i18n.t("text.forgotPassword")}
              </span>
            </div>
            <div
              className={`registerLink ${this.props.theme || "light"}`}
              onClick={() => this.props.popupHandler(<Register />)}
            >
              <i className="icon-sign-up-with-viulive"></i>
              {i18n.t("text.createAccount")}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    checked: state.local.rememberLogin,
    theme: state.local.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    rememberLogin: (checked) => dispatch(rememberLogin(checked)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginForm));
