import { combineReducers } from "redux";
import configureStore from "./createStore";

import {
  eventsReducer,
  locationsReducer,
  authReducer,
  changePasswordReducer,
  streamsReducer,
  errorHandlerReducer,
  getMetaDataReducer,
  tagsReducer,
  userPlanReducer,
  reactionReducer,
  eventViewReducer,
  commentsReducer,
  socializingReducer,
  reportsReducer,
  chatsReducer,
  usersReducer,
  profileReducer,
  mixerReducer,
  viubitReducer,
  invoiceReducer,
  profileNotificationsReducer,
  directorReducer,
} from "@pmviulive/vume-js";

import { localReducer } from "./localRedux/reducers";
/* ------------- Assemble The Reducers ------------- */

export const reducers = combineReducers({
  events: eventsReducer,
  locations: locationsReducer,
  auth: authReducer,
  changePassword: changePasswordReducer,
  streams: streamsReducer,
  errorHandler: errorHandlerReducer,
  metaData: getMetaDataReducer,
  tags: tagsReducer,
  userPlans: userPlanReducer,
  reactions: reactionReducer,
  eventView: eventViewReducer,
  comments: commentsReducer,
  socializing: socializingReducer,
  reports: reportsReducer,
  local: localReducer,
  chats: chatsReducer,
  users: usersReducer,
  profile: profileReducer,
  mixer: mixerReducer,
  viubit: viubitReducer,
  invoice: invoiceReducer,
  notifications: profileNotificationsReducer,
  director: directorReducer,
});

export default () => {
  return configureStore(reducers);
};
