import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationTab from "../../sharedComponents/notificationTab/notificationTab";
import NotificationList from "../../sharedComponents/notificationList/notificationList";
import { Notifi } from "../../../config/icons";
import { Spinner } from "@pmviulive/msd-reactjs";
import useOnClickOutside from "use-onclickoutside";
import {
  getMyNotification,
  getMyNotificationCancel,
  markAllNotificationAsRead,
} from "@pmviulive/vume-js";
import "./notification.scss";
import NotificationHeader from "../../sharedComponents/notificationHeader/notificationHeader";

const Notification = () => {
  const dispatch = useDispatch();
  const ref = useRef();

  const theme = useSelector((state) => state.local.theme);
  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);
  const nots = useSelector((state) => state.notifications.notifications);
  const unRead = useSelector(
    (state) => state.notifications.notifications?.unreadCount
  );
  const notificationLoading = useSelector(
    (state) => state.notifications.loading
  );
  const notificationError = useSelector((state) => state.notifications.error);
  // console.log("unRead", unRead, nots);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [intialLoading, setIntialLoading] = useState(true);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [notification, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [fromDate, setFromDate] = useState(false);
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [noData, setNoData] = useState(false);
  useOnClickOutside(ref, () => setShow(false));

  const handleShow = () => {
    setShow(!show);
  };

  const changeTab = (t) => {
    setIntialLoading(true);
    setNoData(false);
    setNotifications([]);
    setTotalPageNumber(0);
    dispatch(getMyNotificationCancel());
    setActiveTab(t);
  };

  const refreshData = () => {
    setIntialLoading(true);
    setNoData(false);
    setNotifications([]);
    setTotalPageNumber(0);
    dispatch(getMyNotificationCancel());
    dispatch(
      getMyNotification(
        token,
        { unreadOnly: unreadOnly, pageNumber: 0, fromDate: fromDate },
        server
      )
    );
  };

  const loadMore = (t) => {
    if (page < totalPageNumber - 1 && !notificationLoading) {
      let nPageNum = page + 1;
      dispatch(
        getMyNotification(
          token,
          {
            unreadOnly: unreadOnly,
            page: nPageNum,
            fromDate: fromDate ? new Date() - 1 : null,
          },
          server
        )
      );
      setPage(nPageNum);
    }
  };
  useEffect(() => {
    dispatch(
      getMyNotification(
        token,
        { unreadOnly: unreadOnly, pageNumber: 0, fromDate: fromDate },
        server
      )
    );
  }, []);

  useEffect(() => {
    if (token == null) {
      dispatch(getMyNotificationCancel());
      setNotifications([]);
      setIntialLoading(true);
      setPage(0);
      setFromDate(false);
      setNoData(false);
    }
  }, [token]);

  useEffect(() => {
    if (show) {
      dispatch(getMyNotificationCancel());
      setNotifications([]);
      setIntialLoading(true);
      setPage(0);
      setFromDate(false);
      setNoData(false);
      if (activeTab === "today") {
        setPage(0);
        setHasMore(true);
        setFromDate(true);
        setUnreadOnly(false);
        dispatch(
          getMyNotification(
            token,
            {
              unreadOnly: false,
              pageNumber: 0,
              fromDate: new Date() - 1,
            },
            server
          )
        );
      } else if (activeTab === "new") {
        setPage(0);
        setHasMore(true);
        setFromDate(false);
        setUnreadOnly(true);
        dispatch(
          getMyNotification(token, { unreadOnly: true, pageNumber: 0 }, server)
        );
      } else {
        setPage(0);
        setHasMore(true);
        setFromDate(false);
        setUnreadOnly(false);
        dispatch(
          getMyNotification(
            token,
            { unreadOnly: false, pageNumber: 0, fromDate: null },
            server
          )
        );
      }
    }
  }, [activeTab, show]);

  useEffect(() => {
    if (nots && nots.result.length > 0) {
      setHasMore(true);
      setTotalPageNumber(nots.totalPage);
      setNotifications([...notification, ...nots.result]);
      setTimeout(setIntialLoading(false), 500);
    }
  }, [nots]);

  useEffect(() => {
    if (totalPageNumber == page + 1) {
      setHasMore(false);
    }
  }, [totalPageNumber, notification]);

  useEffect(() => {
    if (notificationError && notification.length < 1 && intialLoading) {
      setTimeout(setIntialLoading(false), 700);
      setNoData(true);
    }
  }, [notificationError]);

  const markAsRead = () => {
    markAllNotificationAsRead({
      token,
      server,
    });
  };

  const cancelNotifications = () => {
    dispatch(getMyNotificationCancel());
  };

  return (
    <div className="notification-container" ref={ref}>
      <div
        className="notification-bell"
        onClick={() => {
          markAsRead();
          cancelNotifications();
          handleShow();
        }}
      >
        {unRead ? <span className="notifications-number"></span> : <></>}
        <Notifi theme={theme} width={25} height={25} />
      </div>

      <div className={`notification-body ${show && "active"}`}>
        <NotificationHeader refreshData={refreshData} />
        <NotificationTab
          activeTab={activeTab}
          changeTab={(e) => changeTab(e)}
        />
        <div className="Notification-content">
          {intialLoading && (
            <div className="loader-container">
              <Spinner className="absoluteLoader" />
            </div>
          )}
          {!noData && notification.length > 0 && (
            <NotificationList
              notification={notification}
              CloseOnClick={() => handleShow()}
              loadMore={() => loadMore()}
              hasMore={hasMore}
            />
          )}
          {noData && (
            <div className="no-notification">
              <div className="no-notification-icon">
                <Notifi theme={theme} width={60} height={60} />
              </div>
              <div className="no-notification-text">No Notification Yet</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Notification;
