import * as actionTypes from "./actionTypes";

export const saveToken = () => {
  return {
    type: actionTypes.SAVE_TOKEN,
  };
};

export const saveTokenStart = () => {
  return {
    type: actionTypes.SAVE_TOKEN_START,
  };
};

export const saveTokenSuccess = (token) => {
  return {
    type: actionTypes.SAVE_TOKEN_SUCCESS,
    token: token,
  };
};

// ============================

export const rememberLogin = (rememberLogin) => {
  return {
    type: actionTypes.REMEMBER_LOGIN,
    rememberLogin: rememberLogin,
  };
};

export const rememberLoginSuccess = (rememberLogin) => {
  return {
    type: actionTypes.REMEMBER_LOGIN_SUCCESS,
    rememberLogin: rememberLogin,
  };
};
// ============================

export const saveServer = (server) => {
  return {
    type: actionTypes.SAVE_SERVER,
    server: server,
  };
};
export const saveServerSuccess = (server) => {
  return {
    type: actionTypes.SAVE_SERVER_SUCCESS,
    server: server,
  };
};

// ============================

export const saveFirstTimeLoad = (status) => {
  return {
    type: actionTypes.SAVE_FIRST_TIME_LOAD,
    status: status,
  };
};
export const saveFirstTimeLoadSuccess = (status) => {
  return {
    type: actionTypes.SAVE_FIRST_TIME_LOAD_SUCCESS,
    status: status,
  };
};

// ======================================

export const saveLocations = (locations) => {
  return {
    type: actionTypes.SAVE_LOCATIONS,
    locations: locations,
  };
};
export const saveLocationsSuccess = (locations) => {
  return {
    type: actionTypes.SAVE_LOCATIONS_SUCCESS,
    locations: locations,
  };
};

// ======================================

export const saveCategories = (categories) => {
  return {
    type: actionTypes.SAVE_CATEGORIES,
    categories: categories,
  };
};
export const saveCategoriesSuccess = (categories) => {
  return {
    type: actionTypes.SAVE_CATEGORIES_SUCCESS,
    categories: categories,
  };
};
// ========================================

export const thumbnailDragging = (stream) => {
  return {
    type: actionTypes.THUMBNAIL_DRAGGING,
    stream: stream,
  };
};
export const thumbnailDraggingSuccess = (response) => {
  return {
    type: actionTypes.THUMBNAIL_DRAGGING_SUCCESS,
    response: response,
  };
};

// ========================================

export const thumbnailDropped = () => {
  return {
    type: actionTypes.THUMBNAIL_DROPPED,
  };
};
export const thumbnailDroppedSuccess = (response) => {
  return {
    type: actionTypes.THUMBNAIL_DROPPED_SUCCESS,
    response: response,
  };
};

// ========================================
export const savePrivateEventKey = (accessKey) => {
  return {
    type: actionTypes.SAVE_PRIVATE_EVENT_KEY,
    accessKey: accessKey,
  };
};
export const savePrivateEventKeySuccess = (accessKey) => {
  return {
    type: actionTypes.SAVE_PRIVATE_EVENT_KEY_SUCCESS,
    accessKey: accessKey,
  };
};

// ========================================
export const deletePrivateKeys = () => {
  return {
    type: actionTypes.DELETE_PRIVATE_KEYS,
  };
};
export const deletePrivateKeysSuccess = () => {
  return {
    type: actionTypes.DELETE_PRIVATE_KEYS_SUCCESS,
  };
};
// ============================

export const saveTheme = (theme) => {
  return {
    type: actionTypes.SAVE_THEME,
    theme: theme,
  };
};
export const saveThemeSuccess = (theme) => {
  return {
    type: actionTypes.SAVE_THEME_SUCCESS,
    theme: theme,
  };
};

// ============================

export const saveFavoriteCategories = (favoriteCategories) => {
  return {
    type: actionTypes.SAVE_FAVORITE_CATEGORIES,
    favoriteCategories: favoriteCategories,
  };
};
export const saveFavoriteCategoriesSuccess = (favoriteCategories) => {
  return {
    type: actionTypes.SAVE_FAVORITE_CATEGORIES_SUCCESS,
    favoriteCategories: favoriteCategories,
  };
};
// ============================

export const disableAutoplaySession = (state) => {
  return {
    type: actionTypes.DISABLE_AUTOPLAY_SESSION,
    state: state,
  };
};
export const disableAutoplaySessionSuccess = (state) => {
  return {
    type: actionTypes.DISABLE_AUTOPLAY_SESSION_SUCCESS,
    state: state,
  };
};
