export const QuickStart = [
    {
        id: 1,
        title: 'Single Camera Streaming - Quick Live (Real-time)',
        subTitle:'',
        points: [
            {
                title: 'Add Captions:',
                body:'Choose from 2 text sizes.'
            },
            {
                title: 'Overlay Graphics:',
                body:'Upload and position logos or images over the stream.'
            },
            {
                title: 'Quick Live Preview:',
                body:'Select “Quick Live” on the other intended device to start previewing the outcome on the director screen.',
            },
            {
                title: 'Start Broadcasting:',
                body:'Click “Go Live” on any device to start sharing.',
            }
        ],
        notes: [
            'Preview may take up to 12 seconds to appear.'
        ]
    },
    {
        id: 2,
        title: 'Single Camera Streaming - Quick Live (Predefined)',
        subTitle:'In the predefined mode, you can either create your own setup, use an existing template, or make edits to an existing one.',
        points: [
            // {
            //     title: 'Add Captions:',
            //     body:'Choose from 2 text sizes.',
            // },
            // {
            //     title: 'Overlay Graphics:',
            //     body:'Upload and position logos or images over the stream.',
            // },
            {
                title: 'Quick Live Preview:',
                body:'Select “Quick Live” on the intended device to start previewing the outcome on the director screen.',
            },
            {
                title: 'Start Broadcasting:',
                body:'Click “Go Live” on any device to start sharing.',
            }
        ],
        notes: [
            'Preview may take up to 12 seconds to appear.'
        ]
    },
    {
        id: 3,
        title: 'Multiple Camera Streaming - Quick Live (Real-time)',
        subTitle: 'Make sure to log in with the same credentials across all intended devices.',
        points: [
            // {
            //     title: 'Add Captions:',
            //     body:'Choose from 2 text sizes.',
            // },
            // {
            //     title: 'Overlay Graphics:',
            //     body:'Upload and position logos or images over the stream.',
            // },
            {
                title: 'Quick Live Preview:',
                body:'Select “Quick Live” on the intended device to start previewing the outcome on the director screen.',
            },
            {
                title: 'Start Broadcasting:',
                body:'Click “Go Live” on any device to start sharing.',
            }
        ],
        notes: [
            'Preview may take up to 12 seconds to appear.',
            'Make sure to log in with the same credentials across all intended devices.'
        ]
    },
    {
        id: 4,
        title: 'Multiple Camera Streaming - Quick Live (Predefined)',
        subTitle: 'In the predefined mode, you can either create your own setup, use an existing template, or make edits to an existing one.',
        points: [
            // {
            //     title: 'Add Captions:',
            //     body:'Choose from 2 text sizes.',
            // },
            // {
            //     title: 'Overlay Graphics:',
            //     body:'Upload and position logos or images over the stream.'
            // },
            {
                title: 'Quick Live Preview:',
                body:'Select “Quick Live” on the other intended device to start previewing the outcome on the director screen.',
            },
            {
                title: 'Start Broadcasting:',
                body:'Click “Go Live” on any device to start sharing.',
            }
        ],
        notes: [
            'Preview may take up to 12 seconds to appear.',
            'Make sure to log in with the same credentials across all intended devices.'
        ]
    }
]
