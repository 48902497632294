import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown-now";
import ReactCodeInput from "react-verification-code-input";
import { Form, Input, Button, Spinner } from "@pmviulive/msd-reactjs";
import { checkValidation, changePasswordValidation } from "./validityCheck";
import {
  forgotPasswordSendCode,
  forgotPasswordCheckCode,
  forgotPasswordChangePassword,
} from "@pmviulive/vume-js";
import i18n from "../../../../config/i18n";
import { logEvent } from "../../../../config/analytics";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";

import "./forgotPassword.scss";

class ForgotPassword extends Component {
  state = {
    code: null,
    email: null,
    password: null,
    confirmPassword: null,
    step: 0,
    isCountdownFinish: false,
    countdown: null,
    count: Date.now() + 10 * 60000,
    joinTimes: 0,
  };

  countdown = () => {
    const countdown = setInterval(
      () => this.setState({ count: this.state.count - 1 }),
      1000
    );
    this.setState({ countdown });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.countdown);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step === 1 && prevState.step === 0) {
      this.countdown();
    }
  }

  onChangeHandler = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  emailHandler = (e) => {
    e.preventDefault();
    const valid = checkValidation(this.state.email);
    if (valid) {
      this.props.sendCode(this.state.email, this.props.server);
    }
  };

  changePasswordSubmit = (e) => {
    e.preventDefault();
    const valid = changePasswordValidation(
      this.state.password,
      this.state.confirmPassword
    );
    if (valid) {
      this.props.changePassword(
        this.state.email,
        this.state.code,
        this.state.confirmPassword,
        this.props.server
      );
    }
  };

  checkCodeHandler = (e) => {
    if (e !== this.state.code) {
      this.props.checkCode(this.state.email, e, this.props.server);
    }
    this.setState({
      code: e,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.error &&
      nextProps.error !== this.props.error &&
      this.state.step === 0
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.emailNotExist"),
      });
    }
    if (
      nextProps.response &&
      nextProps.response !== this.props.response &&
      this.state.step === 0
    ) {
      this.setState({ step: 1 });
    }

    if (
      nextProps.error &&
      nextProps.error !== this.props.error &&
      this.state.step === 1 &&
      nextProps.error.type === "error.validation"
    ) {
      this.setState({ joinTimes: this.state.joinTimes + 1 }, () => {
        if (this.state.joinTimes > 10) {
          throwNotification({
            type: "error",
            message: i18n.t("errors.blockedByOtp"),
          });
          this.setState({ step: 0, joinTimes: 0 });
        } else {
          throwNotification({
            type: "error",
            message: i18n.t("errors.verificationCode"),
          });
        }
      });
    }
    if (
      nextProps.response &&
      nextProps.response !== this.props.response &&
      this.state.step === 1
    ) {
      if (nextProps.response === "checkCode") {
        this.setState({ step: 2 });
      }
    }
    if (
      nextProps.response &&
      nextProps.response !== this.props.response &&
      this.state.step === 2
    ) {
      logEvent("FORGET_PASSWORD");
      throwNotification({
        type: "success",
        message: i18n.t("success.changePassword"),
      });
      this.props.onCancel();
    }
    if (
      nextProps.error &&
      nextProps.error !== this.props.error &&
      nextProps.error.statusCode === 400
    ) {
      if (
        nextProps.error.reasons.some((reason) => reason.objectName === "otp") &&
        this.state.step === 2
      ) {
        throwNotification({
          type: "success",
          message: i18n.t("info.otpExpired"),
        });
      }
    }
  }

  renderer = ({ minutes, seconds }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };

  render() {
    return (
      <div className="forgotPasswordForm">
        {this.props.loading && <Spinner className="absoluteLoader" />}

        <h3 style={{ textAlign: "center" }}>
          {!this.state.step ? (
            i18n.t("text.forgotPassword")
          ) : this.state.step === 2 ? (
            i18n.t("text.forgotPassword")
          ) : (
            <>
              <i
                className="icon-create-an-account"
                style={{ marginRight: "10px" }}
              ></i>{" "}
              {i18n.t("text.resetPassword")}
            </>
          )}
        </h3>

        {this.state.step === 0 ? (
          <Form onSubmit={(e) => this.emailHandler(e)}>
            <Form.Item>
              <Input
                type="text"
                placeholder={`${i18n.t("text.email")}*`}
                onChange={(e) => this.onChangeHandler("email", e)}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  className="transparentBtn blockBtn"
                  onClick={(e) => this.emailHandler(e)}
                >
                  {i18n.t("text.reset")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : this.state.step === 1 ? (
          <div className="forgotPasswordVerification">
            <p>{i18n.t("info.verificationHelp")}</p>
            <h3 style={{ textAlign: "center" }}>
              {i18n.t("info.verificationExpire")}
              {!this.state.isCountdownFinish && (
                <Countdown
                  date={this.state.count}
                  renderer={this.renderer}
                  onComplete={() => this.setState({ isCountdownFinish: true })}
                />
              )}
            </h3>
            {!this.state.isCountdownFinish && (
              <ReactCodeInput
                className={`verificationInput ${
                  this.props.error ? "codeError" : ""
                }`}
                onComplete={(e) => this.checkCodeHandler(e)}
              />
            )}
            <div className="resend">
              <span
                onClick={() => {
                  this.setState({
                    isCountdownFinish: false,
                    count: Date.now() + 10 * 60000,
                  });
                  if (!this.state.email) return;
                  this.props.sendCode(this.state.email, this.props.server);
                }}
              >
                <i className="icon-resend"></i>
                {i18n.t("text.resend")}
              </span>
            </div>
          </div>
        ) : this.state.step === 2 ? (
          <Form onSubmit={(e) => this.changePasswordSubmit(e)}>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            ></div>

            <Form.Item>
              <Input
                type="password"
                autoComplete="off"
                onChange={(e) => this.onChangeHandler("password", e)}
                placeholder={`${i18n.t("text.password")}*`}
              />
            </Form.Item>
            <Form.Item>
              <Input
                type="password"
                autoComplete="off"
                onChange={(e) => this.onChangeHandler("confirmPassword", e)}
                placeholder={`${i18n.t("text.confirmPassword")}*`}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: "center" }}>
                <Button
                  htmlType="submit"
                  className="transparentBtn blockBtn"
                  onClick={(e) => this.changePasswordSubmit(e)}
                >
                  <i className="icon-forgot-password"></i>
                  {i18n.t("text.resetPassword")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.auth.response,
    loading: state.auth.loading,
    error: state.auth.error,
    server: state.local.server,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (email, server) =>
      dispatch(forgotPasswordSendCode(email, server)),
    checkCode: (email, code, server) =>
      dispatch(forgotPasswordCheckCode(email, code, server)),
    changePassword: (email, code, newPassword, server) =>
      dispatch(forgotPasswordChangePassword(email, code, newPassword, server)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
