import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { markAllRead, markNotificationAsReadById } from "@pmviulive/vume-js";
import { Tooltip } from "@pmviulive/msd-reactjs";

import "./notificationHeader.scss";
import { set } from "react-ga";
import { useState } from "react";

const NotificationHeader = ({ refreshData }) => {
  const theme = useSelector((state) => state.local.theme);
  const dispatch = useDispatch();
  const [showDrop, setShowDrop] = useState(false);
  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);
  const unRead = useSelector(
    (state) => state.notifications.notifications?.unreadCount
  );
  const nots = useSelector((state) => state.notifications.notifications);

  const MarkAll = () => {
    markAllRead({ token, server });
    setTimeout(() => {
      refreshData();
      setShowDrop(false);
    }, 200);
  };

  const toggleMenu = () => {
    setShowDrop(!showDrop);
  };

  return (
    <div className="Header-container">
      <p>Notifications</p>
      <i onClick={() => toggleMenu()} className="icon-advance-search"></i>
      {showDrop && (
        <div className="drop-list">
          <div className="drop-item" onClick={() => MarkAll()}>
            <svg
              version="1.1"
              fill={theme === "dark" ? "#fff" : "#333"}
              viewBox="0 0 507.506 507.506"
              width={13}
              height={13}
            >
              <path d="M163.865,436.934c-14.406,0.006-28.222-5.72-38.4-15.915L9.369,304.966c-12.492-12.496-12.492-32.752,0-45.248l0,0c12.496-12.492,32.752-12.492,45.248,0l109.248,109.248L452.889,79.942c12.496-12.492,32.752-12.492,45.248,0l0,0c12.492,12.496,12.492,32.752,0,45.248L202.265,421.019C192.087,431.214,178.271,436.94,163.865,436.934z" />
            </svg>
            <span className="drop-item-text">Mark All As Read</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationHeader;
