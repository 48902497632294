import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Reaction,
  Follow,
  Monetization,
  Comment,
  Event,
  ProfileAvatar,
} from "../../../config/icons";
import moment from "moment";
import { markNotificationAsReadById } from "@pmviulive/vume-js";
import { useSelector } from "react-redux";
import "./notificationItem.scss";
import FollowBtn from "./FollowBtn";

const NotificationItem = ({ item, onClick }) => {
  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);
  const unRead = useSelector(
    (state) => state.notifications.notifications?.unreadCount
  );

  const notificationDateSince = (date) => {
    const nDate = moment.utc(date).toDate();
    let seconds = Math.floor((new Date() - nDate) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    } else {
      return Math.floor(seconds) + " seconds";
    }
  };

  const handleClick = (id) => {
    markNotificationAsReadById({ token, notificationId: id, server });
    onClick();
  };

  console.log("item", item.read);

  return (
    <div className="notification-item-container">
      <div className="notification-avatar">
        <div className="notification-type">
          {item?.type == "REACTION" ? (
            <Reaction
              width="14"
              height="14"
              color={item?.read ? "#646375" : "#5c44af"}
            />
          ) : item?.type == "FOLLOWING" ? (
            <Follow
              width="14"
              height="14"
              color={item?.read ? "#646375" : "#5c44af"}
            />
          ) : item?.type == "WATCHING" ? (
            <Event
              width="14"
              height="14"
              color={item?.read ? "#646375" : "#5c44af"}
            />
          ) : item?.type == "VIUBIT_GIFT" ? (
            <Monetization
              width="14"
              height="14"
              color={item?.read ? "#646375" : "#5c44af"}
            />
          ) : (
            <Comment
              width="14"
              height="14"
              color={item?.read ? "#646375" : "#5c44af"}
            />
          )}
        </div>
        {item?.userThumbnail ? (
          <img className="notification-img" src={item?.userThumbnail} />
        ) : (
          <ProfileAvatar width="45" height="45" color={"#333"} />
        )}
      </div>
      {item?.type === "WATCHING" ||
      item?.type === "BROADCASTING" ||
      item?.type === "MODERATION " ? (
        <Link
          onClick={() => handleClick(item?.id)}
          to={`/watch/${item?.data?.eventId}`}
          className="notification-content"
        >
          <div className="notification-text">
            <div className="notification-date">
              {notificationDateSince(item?.time)} ago
            </div>
            <div>{item?.body}</div>
          </div>
          {item?.eventThumbnail ? (
            <img
              className="notification-event-image"
              src={item?.eventThumbnail ? item?.eventThumbnail : null}
            />
          ) : null}
        </Link>
      ) : item?.type === "FOLLOWING" ? (
        <>
          <Link
            onClick={() => handleClick(item?.id)}
            to={`/user/${item?.data?.userId}`}
            className="notification-content"
          >
            <div className="notification-text">
              <div className="notification-date">
                {notificationDateSince(item?.time)} ago
              </div>
              <div className="notification-text">{item?.body}</div>
            </div>
          </Link>
          <FollowBtn followed={item?.followed} userId={item?.data?.userId} />
        </>
      ) : item?.type === "VIUBIT_GIFT" ? (
        <Link
          onClick={() => handleClick(item?.id)}
          to={`/profile/vhistory`}
          className="notification-content"
        >
          <div className="notification-text">
            <div className="notification-date">
              {notificationDateSince(item?.time)} ago
            </div>
            <div className="notification-text">{item?.body}</div>
          </div>
        </Link>
      ) : item?.type === "SCHEDULED" ||
        item?.type === "REACTION" ||
        item?.type === "COMMENT" ? (
        <Link
          onClick={() => handleClick(item?.id)}
          to={`/detail/${item?.data?.eventId}`}
          className="notification-content"
        >
          <div className="notification-text">
            <div className="notification-date">
              {notificationDateSince(item?.time)} ago
            </div>
            <div>{item?.body}</div>
          </div>
          {item?.eventThumbnail ? (
            <img
              className="notification-event-image"
              src={item?.eventThumbnail ? item?.eventThumbnail : null}
            />
          ) : null}
        </Link>
      ) : null}
    </div>
  );
};

export default NotificationItem;
