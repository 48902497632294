import React, { createContext, useState } from 'react';

export const MyContext = createContext();

const MyProvider = ({ children }) => {
    const [tooltip, setTooltip] = useState({
        quickLiveTooltip: false,
        RealTime:false,
    })
    
    return (
        <MyContext.Provider value={{tooltip, setTooltip}}>
            {children}
        </MyContext.Provider>
    );
};

export default MyProvider;