import React, { Component } from "react";
import { connect } from "react-redux";
import {
  userRegisterSendCode,
  userRegisterRegister,
  userLogin,
  getMyPlan,
  setFreePremium,
} from "@pmviulive/vume-js";
import { withRouter } from "react-router-dom";
import { Spinner, Button } from "@pmviulive/msd-reactjs";
import { checkValidation } from "./validityCheck";
import SignupForm from "./signupForm";
import Countdown from "react-countdown-now";
import ReactCodeInput from "react-verification-code-input";
import i18n from "../../../../config/i18n";
import Terms from "../../termsAndConditions/termsAndConditions";
import { logEvent } from "../../../../config/analytics";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";

import "./signup.scss";

class Signup extends Component {
  state = {
    email: null,
    password: null,
    confirmPassword: null,
    identity: {
      avatar: {
        key: "userAvatar",
        status: "NOT_CHANGED",
        encoding: "NONE",
      },
      name: null,
      type: "PERSON",
      citizenshipCountryId:
        sessionStorage.getItem("myLocation") || "5c2ecd7e5adf253980c0cb5e",
      nationalityCountryId:
        sessionStorage.getItem("myLocation") || "5c2ecd7e5adf253980c0cb5e",
    },
    promotionCode:
      this.props.location.search && this.props.location.search.includes("pcode")
        ? this.props.location.search.split("=")[1]
        : null,
    otp: null,
    step: 0,
    termsApprove: false,
    locationStatus: "Pending",
    count: Date.now() + 5 * 60000,
    validity: false,
    scrolled: false,
    joinTimes: 0,
    isCountdownFinish: false,
  };

  countdown = () => {
    const countdown = setInterval(
      () => this.setState({ count: this.state.count - 1 }),
      1000
    );
    this.setState({ countdown });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.countdown);
  };

  componentDidMount() {
    navigator.geolocation.watchPosition(
      (res) => {
        this.setState({ locationStatus: "Approved" });
      },
      (err) => {
        if (err.message.includes("denied")) {
          this.setState({ locationStatus: "Denied" });
        }
      }
    );
  }

  onChangeHandler = (key, e) => {
    if (key === "name") {
      const newValue = {
        ...this.state.identity,
        [key]: e.trim(),
      };
      this.setState({
        identity: newValue,
      });
    }
    if (key === "citizenshipCountryId") {
      const newValue = {
        ...this.state.identity,
        nationalityCountryId: e,
        [key]: e,
      };
      this.setState({
        identity: newValue,
      });
    } else {
      this.setState({
        [key]: e || e.length ? e : null,
      });
    }
  };

  sendCodehandler = (e) => {
    e.preventDefault();
    const validity = checkValidation(this.state);
    this.setState({ validity });
    return validity;
  };

  sendCode = () => {
    this.props.sendCode(
      {
        email: this.state.email.trim(),
        promotionCode: this.state.promotionCode,
      },
      this.props.server
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.auth &&
      this.props.response === 200 &&
      !this.props.loading &&
      !prevProps.auth
    ) {
      logEvent(`REGISTER_VIULIVE`);
    }
    if (this.state.step === 2 && prevState.step === 1) {
      this.countdown();
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 428 &&
      this.props.error.type === "error.duplicateData"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.duplicatedEmail"),
      });
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 400 &&
      this.props.error.type === "error.validation" &&
      this.props.error.reasons[0].field === "promotionCode"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("errors.promotionCode"),
      });
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.props.error.statusCode === 400 &&
      this.props.error.type === "error.validation" &&
      this.props.error.reasons[0].field === "email"
    ) {
      throwNotification({
        type: "error",
        message: i18n.t("validations.validEmail"),
      });
    }
    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 0
    ) {
      this.setState({ step: 2 });
      if (this.state.promotionCode) {
        sessionStorage.setItem("promotionCode", this.state.promotionCode);
      }
    }
    if (
      this.props.error &&
      this.props.error !== prevProps.error &&
      this.state.step === 2
    ) {
      this.setState({ joinTimes: this.state.joinTimes + 1 }, () => {
        if (this.state.joinTimes > 10) {
          throwNotification({
            type: "error",
            message: i18n.t("errors.blockedByOtp"),
          });
          this.setState({ step: 0, joinTimes: 0 });
        } else {
          throwNotification({
            type: "error",
            message: i18n.t("errors.verificationCode"),
          });
        }
      });
    }
    if (
      this.props.response &&
      this.props.response !== prevProps.response &&
      this.state.step === 2
    ) {
      if (this.props.response !== "sendCode") {
        this.props.login(
          this.state.email,
          this.state.confirmPassword,
          this.props.server
        );
      }
    }
    if (this.props.auth && this.props.auth !== prevProps.auth) {
      this.props.getMyPlan(this.props.auth.token, this.props.server);
    }
    if (this.props.myPlan && this.props.myPlan !== prevProps.myPlan) {
      if (sessionStorage.getItem("promotionCode")) {
        this.props.setFreePremium(
          this.props.auth.token,
          sessionStorage.getItem("promotionCode"),
          this.props.server
        );
        sessionStorage.removeItem("promotionCode");
      }
      this.props.onCancel();
    }
    if (
      this.state.step === 1 &&
      this.state.scrolled &&
      this.props.response === "sendCode" &&
      !prevProps.response
    ) {
      console.log(this.props.response, prevProps.response);
      this.setState({ step: 2, scrolled: false });
    }
  }

  renderer = ({ minutes, seconds }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  toggleTerms = () => {
    this.setState({ step: 1 });
  };
  checkCode = (e) => {
    if (e !== this.state.otp) {
      const newState = {
        ...this.state,
        otp: e,
      };
      if (
        !newState.identity.citizenshipCountryId &&
        !newState.identity.nationalityCountryId
      ) {
        newState.identity.citizenshipCountryId = "5c2ecd7e5adf253980c0cb5e";
        newState.identity.nationalityCountryId = "5c2ecd7e5adf253980c0cb5e";
      }
      this.props.register(newState, this.props.server);
    }
    this.setState({ otp: e });
  };

  render() {
    return (
      <div className="signupForm">
        {this.props.loading ? <Spinner className="absoluteLoader" /> : null}
        <h3>
          <i
            className="icon-create-an-account"
            style={{ marginRight: "10px" }}
          ></i>
          {this.state.step === 1
            ? i18n.t("text.termsTitileInfo")
            : i18n.t("text.createAccount")}
        </h3>
        {this.state.step === 0 ? (
          <SignupForm
            promotionCode={this.state.promotionCode}
            sendCodehandler={(e) => this.sendCodehandler(e)}
            onChangeHandler={(key, e) => this.onChangeHandler(key, e)}
            locationStatus={this.state.locationStatus}
            locations={this.props.locations}
            history={this.props.history}
            fireTrems={() => this.toggleTerms()}
            validity={this.state.validity}
          />
        ) : this.state.step === 1 ? (
          <>
            <div>
              <Terms
                onScroll={() => this.setState({ scrolled: true })}
                inRegister={true}
              />
            </div>
            <Button
              style={{ width: "100%", marginTop: "10%" }}
              htmlType="submit"
              className="transparentBtn"
              onClick={() => this.sendCode()}
              disabled={this.state.scrolled ? false : true}
            >
              {i18n.t("text.iAgreeToTheViuliveAgreement")}
            </Button>
          </>
        ) : (
          <div className="forgotPasswordVerification">
            <p>{i18n.t("info.verificationHelp")}</p>
            <h3 style={{ textAlign: "center" }}>
              {i18n.t("info.verificationExpire")}

              {!this.state.isCountdownFinish && (
                <Countdown
                  date={this.state.count}
                  renderer={this.renderer}
                  onComplete={() => this.setState({ isCountdownFinish: true })}
                />
              )}
            </h3>

            {!this.state.isCountdownFinish && (
              <ReactCodeInput
                className={`verificationInput ${
                  this.props.error ? "codeError" : ""
                }`}
                onComplete={(e) => this.checkCode(e)}
                disabled={this.state.disableCode}
              />
            )}

            <div className="resend">
              <span
                onClick={() => {
                  this.props.sendCode(
                    {
                      email: this.state.email,
                      promotionCode: this.state.promotionCode,
                    },
                    this.props.server
                  );
                  this.setState({
                    count: Date.now() + 5 * 60000,
                    isCountdownFinish: false,
                  });
                }}
              >
                <i className="icon-resend"></i>
                {i18n.t("text.resend")}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    auth: state.auth.user,
    response: state.auth.response,
    myPlan: state.userPlans.myPlan,
    server: state.local.server,
    locations: state.local.locations,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (object, server) =>
      dispatch(userRegisterSendCode(object, server)),
    register: (object, server) =>
      dispatch(userRegisterRegister(object, server)),
    login: (username, password, server) =>
      dispatch(userLogin(username, password, server)),
    getMyPlan: (token, server) => dispatch(getMyPlan(token, server)),
    setFreePremium: (token, promotionCode, server) =>
      dispatch(setFreePremium(token, promotionCode, server)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
