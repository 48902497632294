export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_TOKEN_START = "SAVE_TOKEN_START";
export const SAVE_TOKEN_SUCCESS = "SAVE_TOKEN_SUCCESS";
// =============================
export const REMEMBER_LOGIN = "REMEMBER_LOGIN";
export const REMEMBER_LOGIN_SUCCESS = "REMEMBER_LOGIN_SUCCESS";
// =============================
export const SAVE_SERVER = "SAVE_SERVER";
export const SAVE_SERVER_SUCCESS = "SAVE_SERVER_SUCCESS";
// =============================
export const SAVE_LOCATIONS = "SAVE_LOCATIONS";
export const SAVE_LOCATIONS_SUCCESS = "SAVE_LOCATIONS_SUCCESS";
// =============================
export const SAVE_CATEGORIES = "SAVE_CATEGORIES";
export const SAVE_CATEGORIES_SUCCESS = "SAVE_CATEGORIES_SUCCESS";
// =============================

export const SAVE_FIRST_TIME_LOAD = "SAVE_FIRST_TIME_LOAD";
export const SAVE_FIRST_TIME_LOAD_SUCCESS = "SAVE_FIRST_TIME_LOAD_SUCCESS";
// =============================

export const THUMBNAIL_DRAGGING = "THUMBNAIL_DRAGGING";
export const THUMBNAIL_DRAGGING_SUCCESS = "THUMBNAIL_DRAGGING_SUCCESS";
// =============================
export const THUMBNAIL_DROPPED = "THUMBNAIL_DROPPED";
export const THUMBNAIL_DROPPED_SUCCESS = "THUMBNAIL_DROPPED_SUCCESS";

// ================================

export const SAVE_PRIVATE_EVENT_KEY = "SAVE_PRIVATE_EVENT_KEY";
export const SAVE_PRIVATE_EVENT_KEY_SUCCESS = "SAVE_PRIVATE_EVENT_KEY_SUCCESS";

export const DELETE_PRIVATE_KEYS = "DELETE_PRIVATE_KEYS";
export const DELETE_PRIVATE_KEYS_SUCCESS = "DELETE_PRIVATE_KEYS_SUCCESS";
// =============================
export const SAVE_THEME = "SAVE_THEME";
export const SAVE_THEME_SUCCESS = "SAVE_THEME_SUCCESS";
// =============================
export const SAVE_FAVORITE_CATEGORIES = "SAVE_FAVORITE_CATEGORIES";
export const SAVE_FAVORITE_CATEGORIES_SUCCESS =
  "SAVE_FAVORITE_CATEGORIES_SUCCESS";
// =============================
export const DISABLE_AUTOPLAY_SESSION = "DISABLE_AUTOPLAY_SESSION";
export const DISABLE_AUTOPLAY_SESSION_SUCCESS =
  "DISABLE_AUTOPLAY_SESSION_SUCCESS";
