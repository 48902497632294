import * as actionTypes from "./actionTypes";

const initialState = {
  token: null,
  loading: false,
  rememberLogin: true,
  locations: null,
  server: null,
  dndResponse: null,
  accessKeys: [],
  categories: null,
  theme: null,
  disableAutoPlaySession: false,
  firstTimeLoad: true,
  favoriteCategories: [],
};
const saveTokenStart = (state) => {
  const newState = {
    ...state,
    token: null,
    loading: true,
  };
  return newState;
};
const saveTokenSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    token: action.token,
  };
  return newState;
};
// ================
const rememberLoginSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    rememberLogin: action.rememberLogin,
  };
  return newState;
};
// ================

const saveServerSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    server: action.server,
  };
  return newState;
};

// ================

const saveFirstTimeLoadSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    firstTimeLoad: action.status,
  };
  return newState;
};
// ================

const saveLocationsSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    locations: action.locations,
  };
  return newState;
};
// ================

const saveCategoriesSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    categories: action.categories,
  };
  return newState;
};
// ================

const thumbnailDraggingSuccess = (state, action) => {
  const newState = {
    ...state,
    dndResponse: action.response,
  };
  return newState;
};
// ================

const thumbnailDroppedSuccess = (state, action) => {
  const newState = {
    ...state,
    dndResponse: action.response,
  };
  return newState;
};
// ================

const savePrivateEventKeySuccess = (state, action) => {
  const newState = {
    ...state,
    accessKeys: [...state.accessKeys, action.accessKey],
  };
  return newState;
};

// ================

const deletePrivateKeysSuccess = (state, action) => {
  const newState = {
    ...state,
    accessKeys: [],
  };
  return newState;
};

// ================

const saveThemeSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    theme: action.theme,
  };
  return newState;
};
// ================

const saveFavoriteCategoriesSuccess = (state, action) => {
  const newState = {
    ...state,
    favoriteCategories: action.favoriteCategories,
  };
  return newState;
};
// ================

const disableAutoplaySessionSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    disableAutoPlaySession: action.state,
  };
  return newState;
};

export const localReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TOKEN_START:
      return saveTokenStart(state);
    case actionTypes.SAVE_TOKEN_SUCCESS:
      return saveTokenSuccess(state, action);

    case actionTypes.SAVE_SERVER_SUCCESS:
      return saveServerSuccess(state, action);

    case actionTypes.SAVE_FIRST_TIME_LOAD_SUCCESS:
      return saveFirstTimeLoadSuccess(state, action);

    case actionTypes.REMEMBER_LOGIN_SUCCESS:
      return rememberLoginSuccess(state, action);

    case actionTypes.SAVE_LOCATIONS_SUCCESS:
      return saveLocationsSuccess(state, action);

    case actionTypes.SAVE_CATEGORIES_SUCCESS:
      return saveCategoriesSuccess(state, action);

    case actionTypes.THUMBNAIL_DRAGGING_SUCCESS:
      return thumbnailDraggingSuccess(state, action);

    case actionTypes.THUMBNAIL_DROPPED_SUCCESS:
      return thumbnailDroppedSuccess(state, action);

    case actionTypes.SAVE_PRIVATE_EVENT_KEY_SUCCESS:
      return savePrivateEventKeySuccess(state, action);

    case actionTypes.DELETE_PRIVATE_KEYS_SUCCESS:
      return deletePrivateKeysSuccess(state, action);

    case actionTypes.SAVE_THEME_SUCCESS:
      return saveThemeSuccess(state, action);

    case actionTypes.SAVE_FAVORITE_CATEGORIES_SUCCESS:
      return saveFavoriteCategoriesSuccess(state, action);

    case actionTypes.DISABLE_AUTOPLAY_SESSION_SUCCESS:
      return disableAutoplaySessionSuccess(state, action);

    default:
      return state;
  }
};
