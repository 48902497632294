export const webUrl = process.env.REACT_APP_WEB_URL;
export const version = process.env.REACT_APP_VERSION;
export const level = process.env.REACT_APP_LEVEL;
export const googleLoginCode = process.env.REACT_APP_GOOGLE_LOGIN_CODE;
export const facebookLoginCode = process.env.REACT_APP_FACEBOOK_LOGIN_CODE;
export const googleRecaptchaCode = process.env.REACT_APP_GOOGLE_RECAPTCHA;
export const googleRecaptchaCodeV2 = process.env.REACT_APP_GOOGLE_RECAPTCHA_v2;

export const googleApiAddress = (position) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyCKi70Qu_vBcOJ1blG7pCr93Q3M_7-7u7s`;
};
