import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal } from "@pmviulive/msd-reactjs";
import { useSelector } from "react-redux";
import Layout from "./Layout";
import QuickGuideModal from "../QuickGuideModal/QuickGuideModal";

import './RealTimeTipsModal.scss';

const RealTimeTipsModal = ({
    showTooltips,
    directGuide,
    modalVisible,
    setModalVisible,
    quickGuide,
    setQuickGuide,
    useLocalStorage,
}) => {
    const theme = useSelector((state) => state.local.theme);
    const videoRef = useRef(null);
    const [layout, showLayout] = useState(false);

    useEffect(() => {
        // Check if you should show the modal
        if (useLocalStorage && localStorage.getItem("hasShownModal")) {
            setModalVisible(false);
        } else {
            setModalVisible(true);
        }
    }, [useLocalStorage]);

    const closeModal = () => {
        if (useLocalStorage) {
            localStorage.setItem("hasShownModal", "true");
        }
        setModalVisible(false);
    };

    const startTour = () => {
        setQuickGuide(true);
        showLayout(false);
        setModalVisible(false);
        if (useLocalStorage) {
            localStorage.setItem("hasShownModal", "true");
        }
    }

    const rePlay = () => {
        videoRef.current.play();
        showLayout(false)
    }

    const handleSeeking = () => {
        if (videoRef.current.currentTime === videoRef.current.duration) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <>
            <Modal
                visible={modalVisible}
                footer={false}
                onCancel={closeModal}
                className={`onBoard_Container ${theme === 'dark' ? "darkTipModal" : "lightTipModal"}`}
            >
                <video
                    src="/images/director-intro.mp4"
                    muted={!modalVisible}
                    className="onboard-video"
                    playsInline
                    controls
                    onEnded={() => showLayout(true)}
                    onPlay={() => showLayout(false)}
                    onSeeked={handleSeeking}
                    ref={videoRef}
                    loop={false}
                    autoPlay={modalVisible}
                />
                {layout && <Layout onReplay={rePlay} onStart={startTour} />}
            </Modal>

            <QuickGuideModal
                quickGuide={quickGuide}
                setQuickGuide={setQuickGuide}
                showTooltips={showTooltips}
                setModalVisible={setModalVisible}
                directGuide={directGuide}
            />
        </>
    );
}

export default React.memo(RealTimeTipsModal);
