import React, { useState, useEffect, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Logo } from "@pmviulive/msd-reactjs";
import { MobileView, BrowserView } from "react-device-detect";
import MobileSidebar from "../sidebar/mobileSidebar";
import Search from "../search/search";
import Notification from "../../modules/notification/notification";
import { QuestionMark } from "../../../config/icons";
import QuickGuideModal from "../../modules/director/components/QuickGuideModal/QuickGuideModal";
import RealTimeTipsModal from "../../modules/director/components/RealTimeTipsModal/RealTimeTipsModal";
import { MyContext } from "../../sharedComponents/MyProvider/MyProvider";


const HeaderContent = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [quickGuide, setQuickGuide] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const token = useSelector((state) => state.local.token);
  const theme = useSelector((state) => state.local.theme);
  const { setTooltip } = useContext(MyContext);
  
  useEffect(() => {
    token ? setLoggedIn(true) : setLoggedIn(false);
  }, [token]);

  const handleClickOnSearch = () => {
    props.history.push(`/result?`);
  };

  //TO PREVENT THE ONBOARDING VIDEO FROM SHOWING UP IN EVERY PLACE
  useEffect(() => {
    if (props.history.location.pathname !== '/director') {
      setModalVisible(false);
      setTooltip({ quickLiveTooltip: false });
    }
  }, [props.history.location.pathname]);

  useEffect(() => {
    setModalVisible(false);
  }, []);

  return (
    <React.Fragment>
      <Col lg={7} xs={11} md={7} sm={6}>
        <div className="vume-header__logo">
          <div
            className={
              props.collapsed ? "viu-sideBtn closed" : "viu-sideBtn  opend"
            }
            onClick={
              !props.collapsed
                ? props.handleDrawerOpen
                : props.handleDrawerClose
            }
          >
            <span className="viu-sideBtn__span span--one"></span>
            <span className="viu-sideBtn__span span--two"></span>
            <span className="viu-sideBtn__span span--three"></span>
          </div>
          <a href="/">
            <Logo
              style={{ width: "120px" }}
              src={
                process.env.PUBLIC_URL +
                `/images/${
                  props.theme === "dark" ? "vumeLogoDark" : "vumeLogo"
                }.png`
              }
            />
          </a>
        </div>
      </Col>
      <Col md={10} /* xs={4}  */ xs={11} sm={15}>
        <MobileView>
          <span
            className="mobileSearchIcon"
            style={{ textAlign: "right", float: "right" }}
            onClick={handleClickOnSearch}
          >
            <i className="icon-search-box"></i>
          </span>
        </MobileView>
        <BrowserView>
          <Search />
        </BrowserView>
      </Col>
      <Col /* xs={3}  */ md={5} xl={4} xs={2}>
        <MobileView style={{ textAlign: "right" }}>
          <MobileSidebar
            visible={props.collapsed}
            handleDrawerClose={props.handleDrawerClose}
            handleDrawerOpen={props.handleDrawerOpen}
            popupHandler={(component, logo) =>
              props.popupHandler(component, logo)
            }
            closePopup={props.closePopup}
          />
        </MobileView>
      </Col>

          <div style={{display:'flex', alignItems:'center'}}>
            {loggedIn ? (
              <Col xl={8} xs={4}>
                <Notification />
              </Col>
            ) : null}

            {props.history.location.pathname === '/director' ? (
              <QuestionMark onClick={() => setModalVisible(true)} color={theme === 'dark' ? '#EADCFF' : '#3B3B3B'}/>
            ): (
              null
            )}
          </div>

        <RealTimeTipsModal
          showTooltips={() => setTooltip({quickLiveTooltip: true})}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          quickGuide={quickGuide}
          setQuickGuide={setQuickGuide}
        /> 
    </React.Fragment>
  );
};

export default withRouter(HeaderContent);
