import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMyProfile, editMyProfile } from "@pmviulive/vume-js";
import { Row, Col, Button } from "@pmviulive/msd-reactjs";
import { saveFavoriteCategories } from "../../../../../config/store/localRedux/actions";
import "./favorites.scss";

const Favorites = (props) => {
  const [favorites, selectedFavorites] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isSelectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);
  const myProfile = useSelector((state) => state.profile.user);
  const categories = useSelector((state) => state.local.categories);
  const response = useSelector((state) => state.profile.response);
  const loading = useSelector((state) => state.profile.loading);
  useEffect(() => {
    localStorage.setItem("favoriteCategory", true);
    dispatch(getMyProfile(token, localStorage.getItem("userId"), server));
  }, [dispatch, server, token]);

  useEffect(() => {
    if (myProfile) {
      if (myProfile.user?.userSetting?.favoriteCategories) {
        selectedFavorites(myProfile.user.userSetting.favoriteCategories);
        favorites.length < categories.length
          ? setSelectAll(false)
          : setSelectAll(true);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    favorites.length < categories.length
      ? setSelectAll(false)
      : setSelectAll(true);
  }, [favorites]);

  useEffect(() => {
    if (response && saving) {
      if (response.status === 200) {
        dispatch(saveFavoriteCategories(favorites));
        if (props.popup) {
          props.onCancel();
        }
      }
    }
  }, [response, saving]);

  const selectCategory = (id) => {
    selectedFavorites((prevState) => {
      const isExist = prevState.find((f) => f === id);
      let result = [...prevState];
      if (isExist) {
        result = result.filter((f) => f !== id);
      } else {
        result.push(id);
      }
      return result;
    });
  };
  const selectAll = () => {
    if (!isSelectAll) {
      setSelectAll(true);
      selectedFavorites((prevState) => {
        let result = [...prevState];
        categories.map((cat) => {
          const isExist = prevState.find((f) => f === cat.id);
          if (!isExist) {
            result.push(cat.id);
          }
        });

        return result;
      });
    } else {
      setSelectAll(false);
      selectedFavorites([]);
    }
  };
  const isSelected = (id) => {
    const isExist = favorites.find((f) => f === id);
    return isExist ? "selected" : "";
  };
  const saveFavorites = () => {
    const request = {
      identity: {
        ...myProfile.user.identity,
        avatar: {
          key: "userAvatar",
          status: "NOT_CHANGED",
          encoding: "NONE",
        },
        type: "PERSON",
      },
      about: {
        ...myProfile.user.about,
        cover: {
          key: "cover",
          status: "NOT_CHANGED",
          encoding: "NONE",
        },
        equipment: {
          ...myProfile.user.about?.equipment,
          gear: {
            key: "gears",
            status: "NOT_CHANGED",
            encoding: "NONE",
          },
        },
      },
      userSetting: {
        ...myProfile.user.userSetting,
        favoriteCategories: favorites,
      },
    };
    dispatch(
      editMyProfile(
        token,
        request,
        {
          avatar: null,
          cover: null,
          gears: null,
        },
        server
      )
    );
    setSaving(true);
  };
  return (
    <div className="favorites">
      <Row className="categorySelection">
        <h2>Select at least one of your favorite categories</h2>
        <p></p>
        {categories.map((cat) => {
          return (
            <Col md={8} key={cat.id}>
              <div
                className={`categoryBox ${isSelected(cat.id)} ${
                  props.popup ? "popupMode" : ""
                }`}
                onClick={() => selectCategory(cat.id)}
              >
                <div>
                  <img
                    src={`${cat.avatarBaseUrl}light/${cat.id}.png`}
                    style={{ width: "70%", paddingBottom: "10px" }}
                    alt={cat.id}
                  />
                </div>
                {cat.title}
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="textCenter">
        {props.popup && (
          <Button
            className={"transparentBtn"}
            style={{ marginRight: "20px" }}
            size="large"
            onClick={() => props.onCancel()}
          >
            Skip
          </Button>
        )}
        <Button
          className={"transparentBtn"}
          style={{ marginRight: "20px" }}
          size="large"
          onClick={() => selectAll()}
        >
          {!isSelectAll ? "Select All" : "Deselect All"}
        </Button>
        <Button
          className={"purpleBtn"}
          size="large"
          //disabled={favorites.length < 1 ? true : false}
          onClick={saveFavorites}
          loading={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default Favorites;
