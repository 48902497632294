import { fork, all } from "redux-saga/effects";

import {
  watchEvents,
  watchLocations,
  watchAuth,
  watchChangePassword,
  watchStreams,
  watchGetMetaData,
  watchTags,
  watchUserPlans,
  watchReactions,
  watchEventView,
  watchComments,
  watchRoles,
  watchSocializing,
  watchReports,
  watchChats,
  watchUsers,
  watchProfile,
  watchMixer,
  watchViubit,
  watchInvoice,
  watchNotifications,
  watchDirector
} from "@pmviulive/vume-js";
import { watchLocalSaga } from "./localRedux/sagas";

export default function* rootSaga() {
  yield all([
    fork(watchEvents),
    fork(watchStreams),
    fork(watchLocations),
    fork(watchAuth),
    fork(watchChangePassword),
    fork(watchGetMetaData),
    fork(watchTags),
    fork(watchUserPlans),
    fork(watchRoles),
    fork(watchReactions),
    fork(watchEventView),
    fork(watchComments),
    fork(watchSocializing),
    fork(watchLocalSaga),
    fork(watchReports),
    fork(watchChats),
    fork(watchUsers),
    fork(watchProfile),
    fork(watchMixer),
    fork(watchViubit),
    fork(watchInvoice),
    fork(watchNotifications),
    fork(watchDirector),
  ]);
}
