import React, { useState } from "react";
import { useSelector } from "react-redux";
import NotificationItem from "../notificationItem/notificationItem";

import "./notificationList.scss";

const NotificationList = ({
  activeTab,
  notification,
  CloseOnClick,
  loadMore,
  hasMore,
}) => {
  const [load, setLoad] = useState(false);

  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);

  return (
    <div className="notification-list-container">
      {notification.map((notificationItem, i) => (
        <React.Fragment key={`notification-${i}`}>
          <NotificationItem
            item={notificationItem}
            key={notificationItem.id}
            onClick={CloseOnClick}
          />
          <div className="divider" key={`noti-${i}`}></div>
        </React.Fragment>
      ))}

      {hasMore && (
        <div onClick={() => loadMore()} className="load-more">
          <span>{!load ? "Load More" : null}</span>
        </div>
      )}
    </div>
  );
};
export default NotificationList;
