import * as firebase from "firebase/app";

export const logEvent = (eventName) => {
  process.env.REACT_APP_IS_PRODUCTION === "true" &&
    firebase.analytics().logEvent(eventName);
};

export const setUserProperties = (user) =>
  process.env.REACT_APP_IS_PRODUCTION === "true" &&
  firebase.analytics().setUserProperties(user);
