import React, { Component } from "react";
import { Layout as MsdLayout, Modal } from "@pmviulive/msd-reactjs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView,
  isIOS,
  isAndroid,
  isIE,
} from "react-device-detect";
import Sidebar from "./sidebar/sidebar";
import HeaderContent from "./header/header";
import Popup from "../sharedComponents/popup/popup";
import TabNavigator from "../sharedComponents/tabNavigator/tabNavigator";

import "./layout.scss";

const { Header, Content, Sider } = MsdLayout;

class Layout extends Component {
  state = {
    collapsed: false,
    component: null,
    popup: false,
    withoutLogo: false,
    downloadApp: true,
    // showAdBanner: localStorage.getItem("adBanner") ? false : true,
  };
  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize(this));
  }
  componentDidMount() {
    if (isMobile && this.props.location.pathname === "/register") {
      this.setState({
        collapsed: true,
      });
    }
    if (
      isBrowser &&
      document.getElementsByClassName("ant-layout")[0] &&
      document.getElementsByClassName("topTags")[0]
    ) {
      document.getElementsByClassName("ant-layout")[0].style.marginLeft =
        "190px";
      // document.getElementsByClassName("topTags")[0].style.marginLeft = "190px";
    }
    window.addEventListener("resize", () => this.handleResize(this));

    if (window.location.search.includes("?code=") && isMobile) {
      this.setState({ collapsed: true });
    }
  }
  // closeAdBanner = () => {
  //   this.setState({
  //     showAdBanner: false,
  //   });
  //   localStorage.setItem("adBanner", true);
  // };
  handleResize(_this) {
    const antLayout = document.getElementsByClassName("ant-layout")[0];
    const categoryLayout = document.getElementsByClassName("topTags")[0];
    if (window.innerWidth <= 760 && antLayout && categoryLayout) {
      antLayout.style.marginLeft = "0px";
      categoryLayout.style.marginLeft = "0px";
    } else {
      if (!_this.state.collapsed && isBrowser && antLayout && categoryLayout) {
        antLayout.style.marginLeft = "190px";
        antLayout.classList.add("big");
        antLayout.classList.remove("small");
        antLayout.classList.remove("meduim");
        // categoryLayout.style.marginLeft = "190px";
      } else if (
        _this.state.collapsed &&
        isBrowser &&
        antLayout &&
        categoryLayout
      ) {
        antLayout.style.marginLeft = "80px";
        categoryLayout.style.marginLeft = "80px";
        antLayout.classList.add("meduim");
        antLayout.classList.remove("small");
        antLayout.classList.remove("big");
      }
    }
  }
  handleLayout = ({ status }) => {
    const layout = document.getElementsByClassName("ant-layout")[0];

    if (status && isBrowser) {
      layout.style.marginLeft = "80px";
      layout.classList.add("small");
      layout.classList.remove("big");
    } else if (!status && isBrowser) {
      layout.style.marginLeft = "190px";
      layout.classList.add("big");
      document
        .getElementsByClassName("ant-layout")[0]
        .classList.remove("small");
    }
  };

  toggle = () => {
    const status = !this.state.collapsed;
    this.handleLayout({ status });

    if (status) {
      this.drawerClose();
    }
    this.setState({
      collapsed: status,
    });
  };

  handleDrawerClose = () => {
    this.handleLayout({ status: false });
    this.setState({
      collapsed: false,
      component: null,
    });
  };

  handleDrawerOpen = () => {
    this.handleLayout({ status: true });
    this.setState({
      collapsed: true,
    });
  };

  popupHandler = (component, logo) => {
    this.setState({
      component: component,
      popup: true,
      withoutLogo: logo ? true : false,
    });
  };

  closePopup = () => {
    this.setState({
      component: null,
      popup: false,
    });
  };
  drawerClose = () => {
    this.setState({
      component: null,
    });
  };

  render() {
    return !isIE ? (
      <div className={`theme theme--${this.props.theme || "light"}`}>
        {/* <Modal
          visible={this.state.showAdBanner}
          footer={null}
          onCancel={this.closeAdBanner}
          className={`vume-landing-ad ${
            this.props.theme === "dark" ? "darkModal" : ""
          }`}
        >
          <div className={`theme theme--${this.props.theme || "light"}`}>
            <img
              src={process.env.PUBLIC_URL + "/images/banner.webp"}
              style={{ width: "100%", marginTop: "20px" }}
              alt="banner"
            />
          </div>
        </Modal> */}
        <MsdLayout
          className={this.props.location.pathname.substring(1)}
          style={{ minHeight: "100vh" }}
        >
          {this.props.hideLayout ? null : (
            <BrowserView style={{ display: "inline-flex"}}>
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                className="sider vume-sidebar"
                theme="light"
                trigger={null}
              >
                <Sidebar
                  collapsed={this.state.collapsed}
                  toggleClick={this.toggle}
                  popupHandler={(component) => this.popupHandler(component)}
                  drawerClose={this.drawerClose}
                  location={this.props.location}
                  history={this.props.history}
                  quickLiveTooltip={this.props.quickLiveTooltip}
                  setTooltips={this.props.setTooltips}
                />
              </Sider>
            </BrowserView>
          )}

          <MsdLayout>
            {this.props.hideLayout ? null : (
              <React.Fragment>
                {this.state.downloadApp && isMobile ? (
                  <div className="downloadApp">
                    {isAndroid ? (
                      <a
                        href={
                          process.env.REACT_APP_LEVEL !== "prd"
                            ? `https://viulive-s3files.s3-ap-southeast-1.amazonaws.com/v2/${process.env.REACT_APP_LEVEL}/mobileApp/Viulive.apk`
                            : "https://play.google.com/store/apps/details?id=com.millennium.vumemobile&hl=en"
                        }
                        download={`Viulive-${process.env.REACT_APP_LEVEL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/googlePlay.png"
                          }
                          alt="googlePlay"
                        />
                        Download Android app
                      </a>
                    ) : isIOS ? (
                      <a
                        href={
                          process.env.REACT_APP_LEVEL !== "prd"
                            ? "https://testflight.apple.com/"
                            : "https://apps.apple.com/tt/app/viulive/id1493243242?ign-mpt=uo%3D2"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/images/iosLogo${
                              this.props.theme === "dark" ? "-white" : ""
                            }.png`
                          }
                          alt="googlePlay"
                          style={{
                            width: "18px",
                            marginRight: "5px",
                            marginTop: "-2px",
                          }}
                        />
                        Download iOS app
                      </a>
                    ) : null}
                    <span
                      onClick={() =>
                        this.setState({
                          downloadApp: false,
                        })
                      }
                    >
                      X
                    </span>
                  </div>
                ) : null}

                <Header className="header vume-header">
                  <HeaderContent
                    collapsed={this.state.collapsed}
                    toggleClick={this.toggle}
                    handleDrawerOpen={this.handleDrawerOpen}
                    handleDrawerClose={this.handleDrawerClose}
                    popupHandler={(component, logo) =>
                      this.popupHandler(component, logo)
                    }
                    closePopup={this.closePopup}
                    theme={this.props.theme}
                  />
                </Header>
              </React.Fragment>
            )}

            <Content>{this.props.children}</Content>
            {this.props.hideLayout ? null : (
              <MobileView>
                <TabNavigator
                  popupHandler={(component) => this.popupHandler(component)}
                  toggleClick={this.toggle}
                  collapsed={this.state.collapsed}
                />
              </MobileView>
            )}
          </MsdLayout>
        </MsdLayout>

        {this.state.popup ? (
          <Popup
            onCancel={this.closePopup}
            withoutLogo={this.state.withoutLogo}
          >
            {this.state.component}
          </Popup>
        ) : null}
      </div>
    ) : (
      <div> IE is not supported. Download Chrome/Opera/Firefox </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    theme: state.local.theme,
  };
};
export default connect(mapStateToProps)(withRouter(Layout));
