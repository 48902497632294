import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { followUser, unfollowUser } from "@pmviulive/vume-js";

const FollowBtn = ({ userId, followed }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(true);
  const [newFollow, setNewFollow] = useState(false);
  const token = useSelector((state) => state.local.token);
  const server = useSelector((state) => state.local.server);
  const followResponse = useSelector(
    (state) => state.socializing.followResponse
  );
  const theme = useSelector((state) => state.local.theme);

  const followUserAction = () => {
    dispatch(followUser(token, { followedUserId: userId }, server));
  };

  useEffect(() => {
    if (followResponse !== null && followResponse.followedUser?.id == userId) {
      setNewFollow(true);
    }
  }, [followResponse]);

  return (
    <>
      {followed || newFollow ? (
        <button
          className="following-label"
          style={{
            backgroundColor: theme === "dark" ? "transparent" : "#f7f7f7",
            color: theme === "dark" ? "#fff" : "#333",
          }}
        >
          <i className="icon-invite-friends"></i>
          Following
        </button>
      ) : (
        <div>
          {unfollowUser &&
          localStorage.getItem("userId") &&
          !user.currentUserFallow &&
          userId !== localStorage.getItem("userId") ? (
            <button onClick={() => followUserAction()} className="follow">
              <i className="icon-follow"></i>
              Follow
            </button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default FollowBtn;
