import React, { useState, useEffect, useRef, Children } from "react";
import { Modal } from "@pmviulive/msd-reactjs";
import { useSelector } from "react-redux";
import { RightIcon } from "../../../../../config/icons";

import './TooltipModal.scss'
const TooltipModal = ({
    title,
    note,
    onNext,
    visible,
    setVisible,
    top,
    left,
    arrowLeft,
    arrowDownRight,
    arrowRight,
    arrowRightTop,
    finalTooltip,
    maxWidth,
    indicator,
    activeIndex,
    predefinedIndicator,
    arrowDownCenter,
    customArrow,
    right,
    children
}) => {
    const theme = useSelector((state) => state.local.theme);
    return (
        <Modal
            style={{ position: 'absolute', top: top, left: left, right: right, maxWidth: maxWidth, padding: '16px'}}
            visible={visible}
            footer={false}
            onCancel={() => setVisible(false)}
            className={`Tooltip_Container ${theme === 'dark' ? "Tooltip_Container_Dark" : "Tooltip_Container_Light"}`}
            id='tooltip'
        >
            <div className="tooltip_header">{title}</div>
            <p className="tooltip_body">{children}</p>
            {note && <p className="tooltip_note"><span style={{ fontWeight: 700, letterSpacing: '1px' }}>Important:</span>{note}</p>}
            {finalTooltip ? (
                <button className="final_tooltip" onClick={() => setVisible(false)}>Let's go <RightIcon /></button>
            ) : (
                <>
                    <div className="tooltip_btns">
                        <button className="skip_btn" onClick={() => setVisible(false)}>Skip</button>
                        <button className="next_btn" onClick={onNext}>Next <RightIcon /></button>
                    </div>
                    {indicator && (
                        <div className="indicator_container">
                            {[0, 1, 2, 3, 4, 5, 6].map(index => (
                                <span
                                    key={index}
                                    className={` ${index === activeIndex ? "active_indicator" : "indicator"}`} 
                                ></span>
                            ))}
                        </div>
                    )}
                    {predefinedIndicator && (
                        <div className="indicator_container">
                            {[0, 1, 2, 3, 4, 5, 6].map(index => (
                                <span
                                    key={index}
                                    className={` ${index === activeIndex ? "active_indicator" : "indicator"}`}
                                ></span>
                            ))}
                        </div>
                    )}
                </>
            )}
            {arrowLeft ?
                <div className="tooltip_arrow_left"/>
                : arrowDownRight ? <div className="tooltip_arrow_down_right" />
                    : arrowRight ? <div className="tooltip_arrow_right" />
                        : arrowDownCenter ? <div className="tooltip_arrow_down_center" />
                            : arrowRightTop ? <div className="tooltip_arrow_right_top" />
                                : customArrow ? <div className="tooltip_arrow_down_right" style={customArrow} />
                            : <div className="tooltip_arrow_down" />    
            }
        </Modal>
    )
}

export default TooltipModal
