import I18n from "i18n-js";
import { en } from "@pmviulive/vume-js";
// Enable fallbacks if you want `en-US` and `en-GB` to fallback to `en`
I18n.fallbacks = true;
I18n.locale = "en";
I18n.defaultLocale = "en";
// English language is the main language for fall back:
I18n.translations = {
  en: en,
};

export default I18n;
