import { isEmpty, isEmail, isBetweenLength } from "@pmviulive/msd-js";
import i18n from "../../../../config/i18n";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";

export const checkValidation = (username, password) => {
  if (isEmpty(username)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.enterEmail"),
    });
    return false;
  }
  if (!isEmail(username)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.validEmail"),
    });
    return false;
  }
  if (isEmpty(password)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.enterPassword"),
    });
    return false;
  }

  if (!isBetweenLength(password, 8, 30)) {
    throwNotification({
      type: "error",
      message: i18n.t("errors.authFailed"),
    });
    return false;
  }
  return true;
};
