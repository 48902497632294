import React from "react";
import { useSelector } from "react-redux";
import { Modal, Drawer, Logo } from "@pmviulive/msd-reactjs";
import { isMobile } from "react-device-detect";
import i18n from "../../../config/i18n";

import "./popup.scss";

const Popup = (props) => {
  const theme = useSelector((state) => state.local.theme);
  const childrenWithProps = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { onCancel: props.onCancel })
  );

  if (isMobile) {
    return (
      <Drawer
        placement={props.halfDrawer ? "bottom" : "right"}
        closable={false}
        onClose={props.onCancel}
        visible={true}
        height={
          props.halfDrawer ? (props.height ? props.height : "300px") : "100%"
        }
        width="100%"
        className={`${theme === "dark" ? "darkDrawer" : ""}`}
      >
        <div
          className={`${
            theme === "dark" ? "theme theme--dark" : "theme theme--light"
          }`}
        >
          {!props.halfDrawer ? (
            <React.Fragment>
              <span onClick={props.onCancel} className="backIcon">
                <i className="icon-back"></i>
                {i18n.t("text.back")}
              </span>
              {!props.withoutLogo ? (
                <div style={{ margin: "1em", textAlign: "center" }}>
                  <Logo src={process.env.PUBLIC_URL + "/images/vumeLogo.png"} />
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
          {childrenWithProps}
        </div>
      </Drawer>
    );
  } else {
    return (
      <Modal
        centered
        width="450px"
        visible={true}
        footer={false}
        onCancel={props.onCancel}
        className={`${
          theme === "dark"
            ? "theme theme--dark darkModal"
            : "theme theme--light lightModal"
        }`}
      >
        {childrenWithProps}
      </Modal>
    );
  }
};

export default Popup;
