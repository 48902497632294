import React from 'react'
import { StarIcon } from '../../../../../config/icons'



const stars = [
    {
        id: 1,
        size: '30',
        className:'first_star'
    },
    {
        id: 2,
        size: '25',
        className:'second_star'
    },
    {
        id: 3,
        size: '20',
        className:'third_star'
    },
    {
        id: 4,
        size: '20',
        className:'fourth_star'
    },
    {
        id: 5,
        size: '15',
        className:'fifth_star'
    },
]


const Layout = ({onReplay, onStart}) => {
    return (
        <div className='layout_container'>
            <button className='get_start_btn' onClick={onStart}>
                Get Started
                {stars.map((star) => (
                    <span key={star.id} className={star.className}><StarIcon size={star.size} /></span>
                ))}
                <div className='first_slide' />
                <div  className='second_slide'/>
            </button>
            <img src="/images/replay.svg" alt="replay-icon"  className='replay_btn' onClick={onReplay}/>
        </div>
    )
}

export default Layout