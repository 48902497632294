import {
  isEmpty,
  isEmail,
  isBetweenLength,
  isPasswordMatched,
  isMinimumLength,
} from "@pmviulive/msd-js";
import i18n from "../../../../config/i18n";
import { throwNotification } from "../../../sharedComponents/errors/throwError/throwError";

export const checkValidation = (data) => {
  if (isEmpty(data.identity.name)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.fullname"),
    });
    return false;
  }
  if (!isMinimumLength(data.identity.name, 2)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.fullnameMinLength"),
    });
    return false;
  }
  if (!isBetweenLength(data.identity.name, 3, 30)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.fullnameLength"),
    });
    return false;
  }
  if (isEmpty(data.email)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.enterEmail"),
    });
    return false;
  }
  if (!isEmail(data.email)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.validEmail"),
    });
    return false;
  }
  if (isEmpty(data.password)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.enterPassword"),
    });
    return false;
  }
  if (!isBetweenLength(data.password, 8, 30)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.passwordLength"),
    });
    return false;
  }
  if (isEmpty(data.confirmPassword)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.confirmPassword"),
    });
    return false;
  }

  if (!isPasswordMatched(data.password, data.confirmPassword)) {
    throwNotification({
      type: "error",
      message: i18n.t("validations.passwordMatch"),
    });
    return false;
  }
  return true;
};
