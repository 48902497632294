import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import App from "./App";
import createStore from "./config/store/index";

import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_LEVEL == "prd") {
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function no_console() {};
  console.debug = function no_console() {};
  console.info = function no_console() {};
}

const store = createStore();
const stripePromise = loadStripe(
  process.env.REACT_APP_LEVEL === "prd"
    ? "pk_live_51IyXESIgSSfOBJhH2h1uIxC5H4o86loaJbQigJXZWt3ijv2kS29wkvqiaQGTrwHjL81oSfsK12RbdkXlKi71ZQmw00YGnvD9kV"
    : "pk_test_51IyXESIgSSfOBJhHkpuMn9t7FWekurUeQ6zukp3K0jssmMGQnaXWd9Qxu1EbpYDHEBBFOkfXUdREfpWtfQlE6i2g00Bp9I5Joa"
);
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
