import { notification, Modal } from "@pmviulive/msd-reactjs";

export const throwNotification = (config) => {
  const theme = localStorage.getItem("theme") || "light";
  notification[config.type]({
    message: config.message,
    className: `${theme}-notification`,
    description: config.description || null,
  });
};

export const throwModal = (type, props) => {
  const theme = localStorage.getItem("theme") || "light";
  const modal = Modal[type]({
    ...props,
    className: `${theme}-modal-throw`,
  });
  return modal;
};
