
import React, { useState, useEffect } from "react";
import { Modal } from "@pmviulive/msd-reactjs";
import { useSelector } from "react-redux";
import { BulletIconSec, ArrowForward } from "../../../../../config/icons";
import { QuickStart } from "./data";

import './QuickGuideModal.scss';



const QuickGuideModal = ({quickGuide, setQuickGuide, showTooltips, directGuide, setModalVisible}) => {
    const theme = useSelector((state) => state.local.theme);
    const showNextModal = () => {
        setQuickGuide(false);
        // setModalVisible(false);

        if (!directGuide) {
            showTooltips()
        }
    };

    const closeModal = () => {
        setQuickGuide(false);
        // setModalVisible(false);

        if (!directGuide) {
            // showTooltips()
        }
    };

    return (
        <Modal
            visible={quickGuide}
            footer={false}
            onCancel={closeModal}
            className={`QuickGuideModal ${theme === 'dark' ? "QuickGuideModal_Dark" : "QuickGuideModal_Light"}`}
        >
            <div className="QuickGuide_Close" onClick={showNextModal}>
                <span>Next</span>
                <img src='/images/arrow_forward.svg' alt='Arrow Right'/>
            </div>
            <div>
                <h4 className="mb-1">Quick Start Guide</h4>
                <p className="gray_desc mb-2">Welcome to the Director feature, designed to empower streamers with professional-grade effects for their broadcasts. Creating captivating events with a studio-like touch has never been this easy.</p>
                <p className="gray_desc mb-2">Currently, the Director feature is available exclusively on our web platform at  <a className="GuickGuide_link" target="_blank" href="https://viulive.com/">ViuLive.</a></p>
                <p className="gray_desc mb-3"><span className="white_desc">Stream Smartly with Multiple Cameras:</span> Use the Director feature for single/multi-streaming. Just remember, for a smooth Director experience, make sure to log in with the same credentials across all intended devices.</p>
                <p className="white_desc mb-2">Highlights of the Director Features:</p>
                <ul className="feature_list mb-1 ml-2">
                    <li className="white_desc mb-4">Captions and Logos:<span className="gray_desc"> Enhance your streams by incorporating captions and logos.</span></li>
                    <li className="white_desc mb-4">Multi-Camera Flexibility:<span className="gray_desc"> Broadcast from multiple cameras/mobile devices and effortlessly switch between streams and views.</span></li>
                    <li className="white_desc mb-2">Seamless Scene Transitions:<span className="gray_desc"> Elevate your broadcasts with smooth transitions between scenes.</span></li>
                </ul>
                <p className="info white_desc">You can use the Director feature in two ways: Real-time Mode or Predefined Mode.</p>
                <ul className="feature_list mb-5 ml-2">
                    <li className="white_desc mb-4">Real-time Mode:<span className="gray_desc"> As a stream admin, you can dynamically select captions, logos, stream duration, transitions, and stream switching during the live streaming.</span></li>
                    <li className="white_desc mb-4">Predefined Mode:<span className="gray_desc"> Streamers can preconfigure captions, logos, and scene transitions, saving these settings in a template. This template is then automatically applied once your stream starts.</span></li>
                </ul>
                <GuideList />
            </div>
        </Modal>
    )
}

export default QuickGuideModal



const GuideList = () => {
    const [openTabs, setOpenTabs] = useState({});

    const handleOpen = (index) => {
        setOpenTabs((prevOpenTabs) => ({
            ...prevOpenTabs,
            [index]: !prevOpenTabs[index],
        }));
    };

    return (
        <>
            <h4 className="mb-3">
                <span className="ViuLive_logo">ViuLive </span> User Quick Start Guide
            </h4>
            <p className="gray_desc mb-3 ml-3">
                The User Quick Start Guide includes information about the options that are common to most ViuLive users.
                <br /> The articles are designed to give you information and to help you get started.
            </p>
            {QuickStart.map((item) => (
                <ul className="guide_list" key={item.id}>
                    <li
                        onClick={() => handleOpen(item.id)}
                        className={`white_desc mb-6 ml-4 ${openTabs[item.id] ? 'opend_item' : 'closed_item'}`}
                    >
                        <BulletIconSec className={openTabs[item.id] ? 'openedItem' : ''} /> {' '}
                        <span style={{marginLeft:'-5px'}}>{item.title}</span>
                    </li>
                    {openTabs[item.id] && (
                        <div>
                            <p className={`gray_desc ml-6 ${item.id == 2 || item.id == 4 ? 'white_desc info' : ''}`}>{item?.subTitle}</p>
                            <ul className="mb-2 ml-5">
                                {item.points.map((point, index) => (
                                    <li key={index} className="white_desc sub_points mb-4">
                                        {point.title} <span className="gray_desc">{point.body}</span>
                                    </li>
                                ))}

                                {item?.notes.map((note, index) => (
                                    <div
                                        key={index} bv
                                        className={`white_desc mb-2 ${index === 0 ? 'hint' : 'hint_sec'}`}
                                    >
                                        <span className="note">Note:</span>
                                        {note}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )}
                </ul>
            ))}
        </>
    );
};



