import React from "react";
import Menu from "../../layout/sidebar/components/menu";
import { withRouter } from "react-router-dom";

import "./tabNavigator.scss";

const tabNavigator = (props) => {
  return (
    <div className="tabNavigator">
      <Menu
        mode="horizontal"
        collapsed={props.collapsed}
        toggleClick={props.toggleClick}
        drawerHandler={(component) => props.popupHandler(component)}
        location={props.location}
        history={props.history}
      />
    </div>
  );
};

export default withRouter(tabNavigator);
