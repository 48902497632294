import React, { Component } from "react";
import { Input } from "@pmviulive/msd-reactjs";
import { withRouter } from "react-router-dom";
import "./search.scss";
import i18n from "../../../config/i18n";
import { notification } from "antd";

class Search extends Component {
  state = {
    defaultVal: null,
    value: null,
  };

  componentDidMount() {
    if (
      this.props.location.pathname.includes("result") &&
      this.props.location.search
    ) {
      const splittedVal = this.props.location.search.split("?")[1];
      this.setState({
        defaultVal: decodeURIComponent(splittedVal),
        value: decodeURIComponent(splittedVal),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname === "/result" &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      if (!this.state.value) {
        const splittedVal = this.props.location.search.split("?")[1];

        this.setState({
          defaultVal: decodeURIComponent(splittedVal),
          value: decodeURIComponent(splittedVal),
        });
      }
    }
    if (
      JSON.stringify(this.props.location.pathname) !==
        JSON.stringify(prevProps.location.pathname) &&
      this.props.history.action === "POP" &&
      this.props.location.pathname.includes("/result")
    ) {
      const splittedVal = this.props.location.search.split("?")[1];
      this.setState({
        defaultVal: decodeURIComponent(splittedVal),
        value: decodeURIComponent(splittedVal),
      });
      clearTimeout(this.searchTimeout);
    }
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      !this.props.location.pathname.includes("result")
    ) {
      this.setState({ value: null });
    }
  }

  onChange = (e) => {
    clearTimeout(this.searchTimeout);
    if (e.trim().length > 2) {
      notification.destroy();
      this.startSearch(e, 800);
    }
    this.setState({ value: e });
  };
  startSearch = (e, delay) => {
    clearTimeout(this.searchTimeout);
    if (e.trim().length > 2) {
      notification.destroy();
      window.scrollTo(0, 0);
      this.searchTimeout = setTimeout(() => {
        const val = e.trim();
        const search = val.indexOf("#") === 0 ? val.replace("#", "") : val;
        this.props.history.push(`/result?${encodeURIComponent(search)}`);
      }, delay);
    } else {
      notification.destroy();
      notification.warning({
        message: "Please Enter More Than 3 Characters To Start Search  ",
      });
    }
  };
  clearSearch = () => {
    this.setState({ value: null });
    this.props.history.push(`/`);
  };
  render() {
    return (
      <>
        <Input.Search
          onSearch={(e) => {
            if (e) {
              this.startSearch(e, 0);
            } else {
              this.props.history.push("/");
            }
          }}
          className="search"
          defaultValue={this.state.defaultVal}
          value={this.state.value}
          onChange={(e) => {
            this.onChange(e.target.value);
          }}
          placeholder={i18n.t("text.searchbar")}
          onPressEnter={(e) => {
            this.startSearch(e.target.value, 0);
          }}
        />
        {this.state.value && (
          <span className="searchClearButton" onClick={this.clearSearch}>
            X
          </span>
        )}
      </>
    );
  }
}

export default withRouter(Search);
