import React, { Component } from "react";
import { Row, Col, Tooltip } from "@pmviulive/msd-reactjs";
import i18n from "../../../config/i18n";
import { logEvent } from "../../../config/analytics";

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LineShareButton,
  EmailIcon,
  FacebookIcon,
  LineIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";

import "./inviteFriends.scss";

class InviteFriends extends Component {
  state = {
    copied: false,
  };
  inviteFriends = () => {
    logEvent("INVITE_FRIENDS_EMAIL");
  };
  render() {
    const invitationMessage =
      "Join ViuLive and checkout this new app, you can do live streaming with single or multiple cameras, even lets you invite others join you in the broadcasting. It’s great for broadcasting events and sports activities.For more information please visit:  www.viulive.com";
    const publishedLink = "www.viulive.com";
    return (
      <div className="inviteFriends">
        <h3>{i18n.t("text.inviteFriends")}</h3>
        <CopyToClipboard
          text={invitationMessage}
          onCopy={() => {
            this.setState({ copied: true });
            setTimeout(() => {
              this.setState({ copied: false });
            }, 750);
            this.inviteFriends();
          }}
        >
          <Tooltip
            placement="topLeft"
            title={i18n.t("tooltips.copied")}
            visible={this.state.copied}
          >
            <Row
              className="inviteCopyLink"
              justify="space-around"
              align="middle"
            >
              <Col xs={2}>
                <i className="icon-copy" style={{ fontSize: "23px" }}></i>
              </Col>
              <Col xs={21}>{i18n.t("text.copyToClipboard")}</Col>
            </Row>
          </Tooltip>
        </CopyToClipboard>
        <div
          className="inviteList"
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <EmailShareButton url={invitationMessage}>
            <EmailIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteEmail")}</span>
          </EmailShareButton>
        </div>
        <div
          className="inviteList"
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <FacebookShareButton url={publishedLink} quote={invitationMessage}>
            <FacebookIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteFacebook")}</span>
          </FacebookShareButton>
        </div>

        <div
          className="inviteList"
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <TelegramShareButton url={invitationMessage}>
            <TelegramIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteTelegram")}</span>
          </TelegramShareButton>
        </div>

        <div
          className="inviteList"
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <WhatsappShareButton url={invitationMessage}>
            <WhatsappIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteWhatsapp")}</span>
          </WhatsappShareButton>
        </div>

        <div
          className="inviteList"
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <TwitterShareButton url={invitationMessage}>
            <TwitterIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteTwitter")}</span>
          </TwitterShareButton>
        </div>

        <div
          onClick={() => {
            this.inviteFriends();
          }}
        >
          <LineShareButton
            url={`http://${publishedLink}`}
            title={invitationMessage}
          >
            <LineIcon size={32} round={true} />
            <span>{i18n.t("text.inviteObj.inviteLine")}</span>
          </LineShareButton>
        </div>
      </div>
    );
  }
}

export default InviteFriends;
