import * as actions from "./actions";
import { put, all, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";

function* saveTokenSaga() {
  yield put(actions.saveTokenStart());
  let token = null;
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  } else {
    token = sessionStorage.getItem("token");
  }
  yield put(actions.saveTokenSuccess(token));
}
// ==========================

function* saveServerSaga(action) {
  yield put(actions.saveServerSuccess(action.server));
}

// ==========================

function* saveFirstTimeLoadSaga(action) {
  yield put(actions.saveFirstTimeLoadSuccess(action.status));
}

// ==========================

function* rememberLoginSaga(action) {
  yield put(actions.rememberLoginSuccess(action.rememberLogin));
}
// ==========================

function* saveLocationsSaga(action) {
  yield put(actions.saveLocationsSuccess(action.locations));
}

// ==========================

function* saveCategoriesSaga(action) {
  yield put(actions.saveCategoriesSuccess(action.categories));
}
// ==========================

function* thumbnailDraggingSaga(action) {
  yield put(actions.thumbnailDraggingSuccess(action.stream));
}
// ==========================

function* thumbnailDroppedSaga(action) {
  yield put(actions.thumbnailDroppedSuccess(200));
}
// ==========================

function* savePrivateEventKeySaga(action) {
  yield put(actions.savePrivateEventKeySuccess(action.accessKey));
}

// ==========================

function* deletePrivateKeys(action) {
  yield put(actions.deletePrivateKeysSuccess());
}

// ==========================
function* saveThemeSaga(action) {
  yield put(actions.saveThemeSuccess(action.theme));
}
// ==========================
function* saveFavoriteCategoriesSaga(action) {
  yield put(actions.saveFavoriteCategoriesSuccess(action.favoriteCategories));
}
// ==========================

function* disableAutoplaySessionSaga(action) {
  yield put(actions.disableAutoplaySessionSuccess(action.state));
}

export function* watchLocalSaga() {
  yield all([
    takeEvery(actionTypes.SAVE_TOKEN, saveTokenSaga),
    takeEvery(actionTypes.REMEMBER_LOGIN, rememberLoginSaga),
    takeEvery(actionTypes.SAVE_SERVER, saveServerSaga),
    takeEvery(actionTypes.SAVE_FIRST_TIME_LOAD, saveFirstTimeLoadSaga),
    takeEvery(actionTypes.SAVE_LOCATIONS, saveLocationsSaga),
    takeEvery(actionTypes.THUMBNAIL_DRAGGING, thumbnailDraggingSaga),
    takeEvery(actionTypes.THUMBNAIL_DROPPED, thumbnailDroppedSaga),
    takeEvery(actionTypes.SAVE_PRIVATE_EVENT_KEY, savePrivateEventKeySaga),
    takeEvery(actionTypes.SAVE_CATEGORIES, saveCategoriesSaga),
    takeEvery(actionTypes.DELETE_PRIVATE_KEYS, deletePrivateKeys),
    takeEvery(actionTypes.SAVE_THEME, saveThemeSaga),
    takeEvery(actionTypes.SAVE_FAVORITE_CATEGORIES, saveFavoriteCategoriesSaga),
    takeEvery(actionTypes.DISABLE_AUTOPLAY_SESSION, disableAutoplaySessionSaga),
  ]);
}
